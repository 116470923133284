import { useEffect, useState } from "react";

import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from '../../services/firebaseConnection';

import { toast } from 'react-toastify';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import {Paper, InputBase, IconButton} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';

export default function CadastroCidade(){
    const [listaCidades, setListaCidades] = useState([]);
    const [cidade, setCidade] = useState("");
  
    useEffect(()=>{
      async function buscaCidades(){
        let lista = [];
        let cidadesRef = doc(db, "cidades", "altoTiete");
        let result = await getDoc(cidadesRef);  
        result.data().cidades.forEach((element, index) => {
          lista.push({
            id: index,
            cidades: element
          })
          setListaCidades(lista);        
        });      
      }  
     buscaCidades();
    },[])
  
    const DataGrid = styled(MuiDataGrid)(({ theme }) => ({
        //Desativa cabeçalho
        "& .MuiDataGrid-columnHeaders": { display: "none" },
        "& .MuiDataGrid-virtualScroller": { marginTop: "0!important" },    
        //Desativa seleção da célula
        "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
        {
          outline: "none",
        },
      }));
    
      const columns = [
        { field: 'cidades',
          width: 280,
        },
        {
          field: 'delete-cidade',
          align: "center",   
          width: 50,        
          renderCell: (params)=>{
            return (
              <IconButton 
                className='delete-button'
                aria-label="delete" 
              >
                <DeleteIcon />
              </IconButton>
            )
          } 
        },
      ];

      function titleize(text) {
        let words = text.toLowerCase().split(" ");
        for (let a = 0; a < words.length; a++) {
            let w = words[a];
            if(words[a].length>2){
              words[a] = w[0].toUpperCase() + w.slice(1);
            }else{
              words[a] = w;
            }
        }
        return words.join(" ");
      }
    
      async function cadastrarCidade(novaCidade){

        if(cidade=="")return;
        
        let cidadeFormatada = titleize(novaCidade);
        
        let lista = [];
        let listaFB = [];
        let cidadeRef = doc(db, "cidades", "altoTiete");
        lista.push(...listaCidades);
        lista.push({
          id: listaCidades.length,
          cidades: cidadeFormatada
        });
        
        lista.sort(function(a,b){return a.cidades < b.cidades?-1 : a.cidades > b.cidades ? 1 : 0;});
        setListaCidades(lista);
        
        lista.forEach((element)=>{
          listaFB.push(element.cidades);      
        })    
        await updateDoc(cidadeRef, {cidades: listaFB})
        .then(()=>{
          console.log("Cidade gravada com sucesso");
          toast.success("Cidade salva com sucesso!")
        })
        .catch((error)=>{
          console.log("Erro ao gravar no Firestore: "+ error);
          toast.error("Falha ao tentar gravar a cidade!")
        })
      }
    
       async function deleteCidade(item){    
        if(item.field==='delete-cidade'){
          let cidadeRef = doc(db, "cidades", "altoTiete");
          let lista = listaCidades;
          let listaFB = [];
          lista = lista.filter(c=>c.id != item.row.id);
          setListaCidades(lista);
    
          lista.forEach((element)=>{
            listaFB.push(element.cidades);        
          })
    
           await updateDoc(cidadeRef, {cidades: listaFB})
          .then(()=>{
            console.log("Cidade excluida");
            toast.warning(`Cidade ${item.row.cidades} excluida!`);
          })
          .catch((error)=>{
            console.log("Erro ao excluir no Firestore: "+ error);
            toast.error(`Falha ao tentar excluir a cidade ${item.row.cidades}!`);
          })
        }    
      }
    


    return(
        <>
            <h1 className='cidades'>Cadastro de Cidades</h1>
            <Paper
            className='cadastrar-cidade'
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 350 }}
            >
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Cadastrar Cidade"
                className='cadastrar-cidade'
                onChange={(e)=>{setCidade(e.target.value)}}
                inputProps={{ 'aria-label': 'cadastrar cidade' }}
            />
            <IconButton 
                type="button" 
                sx={{ p: '1px', mr:"20px" }} 
                aria-label="cadastrar"
                onClick={()=>{cadastrarCidade(cidade)}}
            >
                <AddBoxIcon fontSize='large' color='success'/>
            </IconButton >
            </Paper>
            <div style={{width:'350px', height:"300px"}}>
            <DataGrid 
                rows={listaCidades} 
                columns={columns} 
                density="compact"
                onCellClick={deleteCidade}
                components={
                {Footer: ()=> null,}
                }
                
            />   
            </div>
        
        </>    
    )


}