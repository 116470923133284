
import './modal.css';
import { useEffect } from 'react'
import { FiX } from 'react-icons/fi';
import { BsWhatsapp } from 'react-icons/bs';

export default function Modal({conteudo, close}){

  useEffect(() => {

    linkWapp();
  		
  }, []);

  function linkWapp() {
    if(conteudo.telefone == null) return;

    let numeroWhats = conteudo.telefone;
    numeroWhats = numeroWhats
      .replace('(', '')
      .replace(')', '')
      .replace('-', '')
      .replace(' ', '');      
    return 'https://api.whatsapp.com/send/?phone=55' + numeroWhats.replace(" ", "");
  }

  return(
    <div className="modal-info">
      <div className="container"  >
        <button className="close-modal" onClick={ close }>
          <FiX size={20} color="#FFF" />          
        </button>

        <div className='teste'>
            <div className='header1'>
                <img className="img" src={conteudo.avatarUrl} alt='avatar' />
                <div className='header-name'>
                    <h2>{conteudo.empresa}</h2>
                    {conteudo.ramo!=null &&
                      <span className="atividade">
                          <a className='ramo'><i>{conteudo.ramo[0]}</i></a>
                          <a className='ramo'><i>{conteudo.ramo[1]}</i></a>
                          <a className='ramo'><i>{conteudo.ramo[2]}</i></a>
                          <a className='ramo'> {conteudo.ramo[3] != null ? "...": null} </a>
                      </span>
                    }
                </div>
            </div>

          {conteudo.responsavel !== null &&
            <div className="row">
              <span>
                Nome do Responsável: <a>{conteudo.responsavel}</a>
              </span>
            </div>
          }

          {conteudo.desconto !== null && (
            <div className="row">
              <span>
                Desconto: <a>{conteudo.desconto}</a>
              </span>
            </div>
          )}
          {(conteudo.cidade !== null && typeof(conteudo.cidade)!= "undefined") &&
            <div className="row">
              <span>
                Cidade: <a>{conteudo.cidade}</a>
              </span>
            </div>
          }

          {(conteudo.endereco !== null && conteudo.endereco !== "" ) &&
            <div className="row">
              <span>
                Endereço: <a>{conteudo.endereco}</a>
              </span>
            </div>  
          }

          {conteudo.instagram !== null && (
            <>
              <div className="row">
                <span>
                  Instagram: <a target="_blank" href={'https://instagram.com/'+conteudo.instagram}>{conteudo.instagram}</a>
                </span>
              </div>
            </>
          )}

          {conteudo.site !== null &&
            <div className="row">
              <span>
                Site: <a href={'https://'+ conteudo.site} target='_blank'> {conteudo.site}</a>
              </span>
            </div>
          }  

          {conteudo.telefone !== null &&
            <div className="row">
              <span>
                Telefone: 
                <a href={linkWapp()} target='_blank' >
                  {conteudo.whatsapp && <BsWhatsapp className='react-icons' size='1.2em' color='green' /> }
                  {conteudo.telefone}
                </a>
              </span>
            </div>
          }

          {conteudo.email !== null &&
            <div className="row">
              <span>
                Email: <a>{conteudo.email}</a>
              </span>
            </div>
          }

          

          {conteudo.desc !== '' && (
            <>
              <h3>Descrição</h3>
              <p>
                {conteudo.desc}
              </p>
            </>
          )}

        </div>
      </div>
    </div>
  )
}