import { useEffect, useState, useContext, useMemo } from "react";
import {
  collection,
  getDocs,
  orderBy,
  query,
  updateDoc,
  doc,
  getDoc,
  increment,
} from "firebase/firestore";
import { db } from "../../services/firebaseConnection";
import avatar from "../../assets/avatar.png";
import emblema from "../../assets/logoShort.png";
import Modal from "../Modal";
import ModalLog from "../Modallog";
import { AuthContext } from "../../contexts/auth";
import { styled } from "@mui/system";
import {
  DataGrid as MuiDataGrid,
  gridPageCountSelector,
  gridPageSelector,
  GridToolbar,
  useGridApiContext,
  useGridSelector,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LinearProgress, TextField } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { ptBR } from "@mui/material/locale";
import "./datagrid.css";
import { BiSearchAlt } from "react-icons/bi";
import { ImEye } from "react-icons/im";
import { TbBuildingChurch } from "react-icons/tb";
import { RiAdminLine } from "react-icons/ri";
import { TbReportSearch } from "react-icons/tb";
import useKeypress from "react-use-keypress";
import { toast } from "react-toastify";

export default function DataTable(item) {
  const { user, setUser, toggleSideBar, setToggleSideBar } =
    useContext(AuthContext);

  const [listaEmpresas, setListaEmpresas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [empresasFiltradas, setEmpresasFiltradas] = useState([]);
  const [showPostModal, setShowPostModal] = useState(false);
  const [showLogModal, setShowLogModal] = useState(false);
  const [detail, setDetail] = useState();
  const [email] = useState(user && user.email);

  const [membroWayVisible, setMembrowayVisible] = useState(true);
  const [descontoVisible, setDescontoVisible] = useState(true);
  const [instagramVisible, setInstagramVisible] = useState(true);
  const [cidadeVisible, setCidadeVisible] = useState(true);
  const [ramoVisible, setRamoVisible] = useState(true);
  const [textPesquisa, setTextPesquisa] = useState("");

  const theme = createTheme(ptBR);

  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <Pagination
        color="warning"
        boundaryCount={1}
        count={pageCount}
        siblingCount={0}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }

  useEffect(() => {
    loadGridEmpresas();
    responsiveColumns();
    return () => {};
  }, []);

  useEffect(() => {
    function arrayUpdate(e) {
      let searchText = e
        .toUpperCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      const empFiltradas = listaEmpresas.filter(function (emp) {
        //Tirando os acentos e verificando se é nulo
        let empresa = (emp.empresa === null ? "" : emp.empresa)
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        let cidade = (
          emp.cidade === null || typeof emp.cidade == "undefined"
            ? ""
            : emp.cidade
        )
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");

        let ramo = (emp.ramo === null ? [""] : emp.ramo)
          .join()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        let desc = (emp.desc === null ? "" : emp.desc)
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        let responsavel = (emp.responsavel === null ? "" : emp.responsavel)
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");

        let res =
          empresa.toUpperCase().includes(searchText) ||
          cidade.toUpperCase().includes(searchText) ||
          ramo.toUpperCase().includes(searchText) ||
          desc.toUpperCase().includes(searchText) ||
          responsavel.toUpperCase().includes(searchText);
        return res;
      });
      setEmpresasFiltradas(empFiltradas);
    }
    arrayUpdate(textPesquisa);
  }, [textPesquisa]);

  async function loadGridEmpresas() {
    let lista = [];
    let numfila = 1;
    const q = query(
      collection(db, "empresas"),
      orderBy("pontos", "desc"),
      orderBy("dataCadastro", "asc")
    );
    const result = await getDocs(q).catch((error) => {
      console.log(error);
    });
    result.forEach((doc) => {
      //Caso a cidade for vazia, não vai pro grid
      if (doc.data().cidade == null && !item.admin) return;

      lista.push({
        fila: numfila,
        id: doc.id,
        responsavel: doc.data().nome == null ? null : doc.data().nome,
        avatarUrl: doc.data().avatarUrl == null ? avatar : doc.data().avatarUrl,
        cidade: doc.data().cidade,
        desc: doc.data().desc == null ? "" : doc.data().desc,
        desconto:
          doc.data().desconto == null ? null : doc.data().desconto + " %",
        empresa: doc.data().empresa,
        endereco: doc.data().endereco == null ? null : doc.data().endereco,
        instagram: doc.data().instagram == null ? null : doc.data().instagram,
        membroWay: doc.data().membroWay == true ? emblema : null,
        ramo:
          doc.data().ramoAtividade == null ? null : doc.data().ramoAtividade,
        site: doc.data().site == null ? null : doc.data().site,
        telefone: doc.data().telefone == null ? null : doc.data().telefone,
        whatsapp: doc.data().whatsapp,
        email: doc.data().email,
        pontos: doc.data().pontos,
        isAdmin: doc.data().isAdmin,
      });
      if (doc.data().email === email && doc.data().cidade !== null) {
        //se o email for do user logado e houver cidade cadastrada
        //seta posição no ranking
        let data = {
          ...user,
          rank: numfila,
        };
        setUser(data);
      }
      numfila = numfila + 1;
    });
    setListaEmpresas(lista);
    setEmpresasFiltradas(lista);
    setIsLoading(false);
  }

  //document.body.onresize = function() {
  function responsiveColumns() {
    if (document.body.clientWidth > 1280) {
      setMembrowayVisible(true);
      setDescontoVisible(true);
      setInstagramVisible(true);
      setCidadeVisible(true);
      setRamoVisible(true);
      if (typeof toggleSideBar != "undefined") setToggleSideBar(false);
    } else if (document.body.clientWidth > 1180) {
      setMembrowayVisible(false);
      setDescontoVisible(true);
      setInstagramVisible(true);
      setCidadeVisible(true);
      setRamoVisible(true);
      if (typeof toggleSideBar != "undefined") setToggleSideBar(false);
    } else if (document.body.clientWidth > 1085) {
      setMembrowayVisible(false);
      setDescontoVisible(false);
      setInstagramVisible(true);
      setCidadeVisible(true);
      setRamoVisible(true);
      if (typeof toggleSideBar != "undefined") setToggleSideBar(false);
    } else if (document.body.clientWidth > 880) {
      setMembrowayVisible(false);
      setDescontoVisible(false);
      setInstagramVisible(false);
      setCidadeVisible(true);
      setRamoVisible(true);
      if (typeof toggleSideBar != "undefined") setToggleSideBar(false);
    } else if (document.body.clientWidth > 510) {
      setMembrowayVisible(false);
      setDescontoVisible(false);
      setInstagramVisible(false);
      setCidadeVisible(false);
      setRamoVisible(true);
      if (typeof toggleSideBar != "undefined") setToggleSideBar(true);
    } else if (document.body.clientWidth > 0) {
      setMembrowayVisible(false);
      setDescontoVisible(false);
      setInstagramVisible(false);
      setCidadeVisible(false);
      setRamoVisible(false);
      if (typeof toggleSideBar != "undefined") setToggleSideBar(true);
    }
  }

  const DataGrid = styled(MuiDataGrid)(({ theme }) => ({
    //Desativa seleção da célula
    "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
      {
        outline: "none",
      },
  }));

  document.body.onresize = () => responsiveColumns();

  const allColumns = useMemo(() => [
    {
      field: "fila",
      headerName: "#",
      width: 10,
      filterable: false,
      headerClassName: "grid-header",
    },
    {
      field: "avatarUrl",
      headerName: "",
      width: 70,
      align: "center",
      filterable: false,
      headerClassName: "grid-header",
      renderCell: (params) => {
        return (
          <div className="div-imgGrid">
            <img className="imgGrid" src={params.row.avatarUrl} alt="avatar" />
          </div>
        );
      },
    },
    {
      field: "empresa",
      headerName: "Empresa",
      width: 180,
      headerClassName: "grid-header",
      description: "Nome da Empresa",

      renderCell: (cellValues) => {
        return (
          <TextField
            className="textcell"
            value={cellValues.row.empresa}
            multiline
            variant="standard"
            InputProps={{
              disableUnderline: true, // <== added this
              readOnly: true,
            }}
          />
        );
      },
    },
    {
      field: "email",
      headerName: "email",
      width: 180,
      hide: item.admin ? false : true,
      headerClassName: "grid-header",
    },
    {
      field: "ramo",
      headerName: "Ramo de Atividade",
      width: 230,
      hide: item.admin ? true : !ramoVisible,
      headerClassName: "grid-header",
      description: "Ramo de Atividade",
    },
    {
      field: "cidade",
      headerName: "Cidade",
      width: 130,
      hide: item.admin ? true : !cidadeVisible,
      headerClassName: "grid-header",
      description: "Cidade em que a empresa está situada",
    },
    {
      field: "instagram",
      headerName: "Instagram",
      width: 150,
      hide: item.admin ? true : !instagramVisible,
      headerClassName: "grid-header",
      description: "Link para o instagram da empresa",
      renderCell: (params) => (
        <a
          target="_blank"
          rel="noreferrer"
          href={"https://instagram.com/" + params.row.instagram}
        >
          {params.row.instagram}
        </a>
      ),
    },
    {
      field: "desconto",
      headerName: "Desconto",
      width: 100,
      hide: item.admin ? true : !descontoVisible,
      headerClassName: "grid-header",
      description: "Desconto exclusivo para comunidade WayNetwork",
    },
    {
      field: "pontos",
      headerClassName: "grid-header",
      headerName: "Pontos",
      valueSetter: atualizaPontos,
      hide: item.admin ? false : true,
      type: "number",
      editable: true,
      width: 80,
    },
    {
      field: "membroWay",
      headerName: "Membro Way",
      width: 105,
      headerAlign: "center",
      headerClassName: "grid-header",
      align: "center",
      hide: item.admin ? false : !membroWayVisible,
      description: "Membros da Way House Church ganham um emblema",
      renderCell: (params) => {
        return (
          <div>
            <img width="40px" src={params.row.membroWay} alt="" />
          </div>
        );
      },
    },
    {
      field: "isAdmin",
      headerClassName: "grid-header",
      hide: item.admin ? false : true,
      type: "boolean",
      headerName: "Admin?",
      width: 80,
    },
    {
      field: "olho",
      headerName: "+ info",
      align: "center",
      headerAlign: "center",
      width: 65,
      headerClassName: "grid-header",
      cellClassName: "cell-olho",
      renderCell: (params) => {
        return (
          <div className="div-olho">
            <ImEye size={25} />
          </div>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      width: 85,
      headerClassName: "grid-header",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<TbBuildingChurch size={25} />}
          label="Membro Way?"
          onClick={() => toggleWay(params.row)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<RiAdminLine size={25} />}
          label="É Admin?"
          onClick={() => toggleAdmin(params.row)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<TbReportSearch size={25} />}
          label="Log"
          onClick={() => toggleModal(params, "log")}
        />,
      ],
    },
  ]);

  async function toggleAdmin(row) {
    let docRef = doc(db, "empresas", row.id);
    await updateDoc(docRef, { isAdmin: !row.isAdmin })
      .then(
        () => (
          setEmpresasFiltradas((prevRows) =>
            prevRows.map((itemArray) =>
              itemArray.id === row.id
                ? { ...itemArray, isAdmin: !itemArray.isAdmin }
                : itemArray
            )
          ),
          setListaEmpresas((prevRows) =>
            prevRows.map((itemArray) =>
              itemArray.id === row.id
                ? { ...itemArray, isAdmin: !itemArray.isAdmin }
                : itemArray
            )
          ),
          toast.success("Admin configurado com sucesso!")
        )
      )
      .catch(() => console.log("Falha ao marcar como admin!"));
  }

  function toggleWay(row) {
    let pontosRef = doc(db, "pontos", "altoTiete");
    let empresaRef = doc(db, "empresas", row.id);
    //Pega pontos à ser somado ou subtraido
    getDoc(pontosRef)
      .then((item) => {
        //Atualiza empresa com pontos e Membro Way
        updateDoc(empresaRef, {
          pontos: row.membroWay
            ? increment(item.data().membroWay * -1)
            : increment(item.data().membroWay),
          membroWay: !row.membroWay,
        })
          .then(() => {
            //Atualiza os Arrays e Grid
            row.membroWay
              ? toast.info(
                  "Removido " +
                    item.data().membroWay +
                    " pontos para esta empresa."
                )
              : toast.info(
                  "Adicionando " +
                    item.data().membroWay +
                    " pontos para esta empresa."
                );
            atualizaGrid(row, item.data().membroWay);
          })
          .catch(() => {
            toast.error("erro ao atualizar empresa");
          });
      })
      .catch(() => {
        console.log("não foi possível acessar os pontos");
        toast.error("não foi possível acessar os pontos");
      });
  }

  function atualizaGrid(row, pts) {
    let teste = empresasFiltradas.map((itemArray) =>
      itemArray.id === row.id
        ? {
            ...itemArray,
            membroWay: row.membroWay == null ? emblema : null,
            pontos: row.membroWay == null ? row.pontos + pts : row.pontos - pts,
          }
        : itemArray
    );
    setEmpresasFiltradas(teste);
    teste = listaEmpresas.map((itemArray) =>
      itemArray.id === row.id
        ? {
            ...itemArray,
            membroWay: row.membroWay == null ? emblema : null,
            pontos: row.membroWay == null ? row.pontos + pts : row.pontos - pts,
          }
        : itemArray
    );
    setListaEmpresas(teste);
  }

  function toggleModal(item, button) {
    if (item.field === "olho") {
      setShowPostModal(!showPostModal); //trocando de true pra false
      setDetail(item.row);
    } else if (button == "log") {
      setDetail(item.row);
      setShowLogModal(!showLogModal);
      //console.log(showLogModal);
    }
  }

  function atualizaPontos(params) {
    let pontos = params.value;
    let empresasRef = doc(db, "empresas", params.row.email);
    updateDoc(empresasRef, { pontos: params.value }).then(() =>
      console.log("Pontos atualizados com sucesso")
    );

    setListaEmpresas(
      listaEmpresas.map((itemArray) =>
        itemArray.id === params.row.id
          ? { ...itemArray, pontos: params.value }
          : itemArray
      )
    );

    setEmpresasFiltradas(
      empresasFiltradas.map((itemArray) =>
        itemArray.id === params.row.id
          ? { ...itemArray, pontos: params.value }
          : itemArray
      )
    );

    //toast.success("Agora a empresa "+ params.row.empresa + " tem " + params.value + " pontos.");
    return { ...params.row, pontos };
  }

  function closeModal() {
    setShowPostModal(false);
    setShowLogModal(false);
  }

  const selectedColumns = user
    ? user.isAdmin && item.admin
      ? allColumns
      : allColumns.filter(function (col) {
          return col.field !== "actions" && col.field !== "isAdmin";
        })
    : allColumns.filter(function (col) {
        return col.field !== "actions" && col.field !== "isAdmin";
      });

  useKeypress("Escape", () => {
    setShowPostModal(false);
  });

  return (
    <>
      <div
        className="search-div"
        id={
          toggleSideBar && item.page != "site"
            ? "search-ativo"
            : "search-inativo"
        }
      >
        <BiSearchAlt size={25} />
        <input
          className="input-search"
          type="search"
          onChange={(e) => {
            setTextPesquisa(e.target.value);
          }}
          placeholder="Empresa, atividade, produto, serviço..."
        />
      </div>
      <ThemeProvider theme={theme}>
        <div className="div-grid" style={{ width: "100%" }}>
          <DataGrid
            rows={empresasFiltradas}
            pageSize={item.pageSize}
            rowsPerPageOptions={[5]}
            columns={selectedColumns}
            disableColumnMenu={false}
            loading={isLoading}
            density="standard"
            autoHeight={true}
            onCellClick={toggleModal}
            getCellClassName={(params) => {
              if (params.field === "olho") {
                return "cell-olho";
              }
            }}
            components={
              item.admin
                ? {
                    Toolbar: GridToolbar,
                    Pagination: CustomPagination,
                    LoadingOverlay: LinearProgress,
                    NoRowsOverlay: () => (
                      <div height="100%">Sem resultados</div>
                    ),
                  }
                : {
                    Pagination: CustomPagination,
                    LoadingOverlay: LinearProgress,
                    NoRowsOverlay: () => (
                      <div height="100%">Sem resultados</div>
                    ),
                  }
            }
          />
        </div>
        {showPostModal && <Modal conteudo={detail} close={closeModal} />}
        {showLogModal && <ModalLog conteudo={detail} close={closeModal} />}
      </ThemeProvider>
    </>
  );
}
