
import './modalLog.css'
import { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';

import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from '../../services/firebaseConnection';

import { FiX } from 'react-icons/fi';

export default function ModalLog({conteudo, close}){

    const [ rows, setRows ] = useState([]);

    useEffect(()=>{
        async function getLog(){
            let logRef = query(collection(db, "empresas", conteudo.email, "log"), orderBy("date", "desc") );
            let lista = [];
        
            const querySnapshot = await getDocs(logRef);
            querySnapshot.forEach((doc) => {
                let dt = new Date(doc.data().date.seconds *1000 + doc.data().date.nanoseconds / 1000000)
                lista.push({
                    id: doc.id,
                    data: dt.toLocaleString("pt-BR", "short"),
                    log: doc.data().log
                })        
            });
            setRows(lista);
        }
        getLog();

    }, [])

    const columns = [
        {
            field: "data",
            width: 170
        },
        {
            field: "log",
            width: 150
        },
    ]


  return(
    <div className="modal-info">
      <div className="container">
            <button className="close-modal" onClick={ close }>
            <FiX size={20} color="#FFF" />          
            </button>

            <div className='conteudo'>
                <h2>{conteudo.empresa}</h2>
                <h4>{conteudo.email}</h4>
                <div className='datagrid'>
                    <DataGrid 
                        rows={rows} 
                        columns={columns} 
                        density="compact"
                        components={
                            {Footer: ()=> null,}
                        }                        
                    />
                </div>
            </div>            
        </div>
    </div>
  )
}