import "./cadastroRamo.css";
import { useEffect, useState } from "react";

import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from '../../services/firebaseConnection';

import { toast } from 'react-toastify';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import {Paper, InputBase, IconButton} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';

export default function CadastroRamo(){

    const [listaRamo, setListaRamo] = useState([]);
    const [ramoFiltrado, setRamoFiltrado] = useState([]);
    const [ramo, setRamo] = useState("");  

    useEffect(()=>{
        async function buscaRamo(){
          let lista = [];
          let ramoRef = doc(db, "ramoAtividade", "o9aTcnd6RDGzsbkYwDac");
          let result = await getDoc(ramoRef);  
          result.data().ramo.forEach((element, index) => {
            lista.push({
              id: index,
              ramo: element
            })
            setListaRamo(lista);   
            setRamoFiltrado(lista);
          });      
        }  
       buscaRamo();
      },[])

    const DataGrid = styled(MuiDataGrid)(({ theme }) => ({
    //Desativa cabeçalho
    "& .MuiDataGrid-columnHeaders": { display: "none" },
    "& .MuiDataGrid-virtualScroller": { marginTop: "0!important" },    
    //Desativa seleção da célula
    "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
    {
        outline: "none",
    },
    }));
      

    const columns = [
    { field: 'ramo',
        width: 280,
    },
    {
    field: 'delete-ramo',
    align: "center",   
    width: 50,        
    renderCell: (params)=>{
    return (
        <IconButton 
        className='delete-button'
        aria-label="delete" 
        >
        <DeleteIcon />
        </IconButton>
    )
    } 
    },
    ];

    function titleize(text) {
        let words = text.toLowerCase().split(" ");
        for (let a = 0; a < words.length; a++) {
            let w = words[a];
            if(words[a].length>2){
              words[a] = w[0].toUpperCase() + w.slice(1);
            }else{
              words[a] = w;
            }
        }
        return words.join(" ");
      }

      
    async function cadastrarRamo(novoRamo){
        if(ramo=="") return;

        let ramoFormatado = titleize(novoRamo);

        let lista = [];
        let listaFB = [];
        let ramoRef = doc(db, "ramoAtividade", "o9aTcnd6RDGzsbkYwDac");
        lista.push(...listaRamo);
        lista.push({
            id: listaRamo.length,
            ramo: ramoFormatado
        });
        
        lista.sort(function(a,b){return a.ramo < b.ramo?-1 : a.ramo > b.ramo ? 1 : 0;});
        setListaRamo(lista);
        setRamoFiltrado(lista);
        
        lista.forEach((element)=>{
            listaFB.push(element.ramo);      
        })    
        await updateDoc(ramoRef, {ramo: listaFB})
        .then(()=>{
            console.log("Ramo gravada com sucesso");
            toast.success("Ramo de atividade salvo com sucesso!")
        })
        .catch((error)=>{
            console.log("Erro ao gravar no Firestore: "+ error);
            toast.error("Falha ao tentar gravar!")
        })
    }

   async function deleteRamo(item){    
    if(item.field==='delete-ramo'){
        let ramoRef = doc(db, "ramoAtividade", "o9aTcnd6RDGzsbkYwDac");
      let lista = listaRamo;
      let listaFB = [];
      lista = lista.filter(c=>c.id != item.row.id);
      setListaRamo(lista);
      setRamoFiltrado(lista);

      lista.forEach((element)=>{
        listaFB.push(element.ramo);        
      })

       await updateDoc(ramoRef, {ramo: listaFB})
      .then(()=>{
        console.log("Cidade excluida");
        toast.warning(`Cidade ${item.row.ramo} excluida!`);
      })
      .catch((error)=>{
        console.log("Erro ao excluir no Firestore: "+ error);
        toast.error(`Falha ao tentar excluir a cidade ${item.row.ramo}!`);
      })
    }    
  }

  function filtroDataGrid(e){
    setRamo(e);
    let searchText = e.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    let ramoFilt =  listaRamo.filter(function(emp) {
        //Tirando os acentos e verificando se é nulo
        let ramo = (emp.ramo === null? "" : emp.ramo).normalize("NFD").replace(/[\u0300-\u036f]/g, "");

        let res = ramo.toUpperCase().includes(searchText);
        return res;
    });
    setRamoFiltrado(ramoFilt);    
  }

      
    return(
        <>
            <h1>Cadastro de Ramo de Atividade</h1>
            <Paper
                className='cadastrar-cidade'
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 350 }}
            >
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Cadastrar Ramo de Atividade"
                onChange={(e)=> filtroDataGrid(e.target.value)}
                inputProps={{ 'aria-label': 'cadastrar ramo' }}
            />
            <IconButton 
                type="button" 
                sx={{ p: '1px', mr:"20px" }} 
                aria-label="cadastrar"
                onClick={()=>{cadastrarRamo(ramo)}}
            >
                <AddBoxIcon fontSize='large' color='success'/>
            </IconButton >
            </Paper>
            <div style={{width:'350px', height:"300px"}}>
                <DataGrid 
                    rows={ramoFiltrado} 
                    columns={columns} 
                    density="compact"
                    onCellClick={deleteRamo}
                    components={
                        {Footer: ()=> null,}
                    }                    
                />   
            </div>
            
        </>

    )
}