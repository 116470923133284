import * as React from "react";
import { useContext, useState, useEffect } from "react";
import "./header.css";
import { AuthContext } from "../../contexts/auth";
import "tippy.js/dist/tippy.css"; // optional
import "tippy.js/themes/light.css";

import avatar from "../../assets/avatar.png";
import logo from "../../assets/logo-way.png";

import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Link, useLocation } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import { MdStoreMallDirectory } from "react-icons/md";
import { TbListDetails } from "react-icons/tb";
import { RiAdminLine } from "react-icons/ri";
import { MdOutlineSpeakerNotes } from "react-icons/md";
import { GoAlert } from "react-icons/go";

import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../services/firebaseConnection";

export default function Header() {
  const {
    user,
    signOutSystem,
    toggleSideBar,
    setToggleSideBar,
    reloadUser,
    somaPontosEmailVerificado,
  } = useContext(AuthContext);
  const location = useLocation();

  const [pontos, setPontos] = useState(0);

  useEffect(() => {
    if (user != null) {
      onSnapshot(
        doc(db, "empresas", user.email),
        (doc) => {
          setPontos(doc.data().pontos);
        },
        (error) => {
          console.log("Erro no ouvinte Firebase: " + error);
        }
      );

      let navigationType = performance.getEntriesByType("navigation")[0].type;

      if (
        user &&
        (navigationType === "reload" || navigationType === "navigate")
      ) {
        reloadUser();
        somaPontosEmailVerificado(user.email);
      }
    }
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  document.body.onresize = function () {
    if (document.body.clientWidth < 700) setToggleSideBar(true);
    if (document.body.clientWidth >= 700) setToggleSideBar(false);
  };
  function toggleMenu() {
    setToggleSideBar(!toggleSideBar);
  }

  return (
    <div
      className={"sidebar"}
      id={toggleSideBar ? "sidebar-ativo" : "sidebar-inativo"}
    >
      <button
        className="btn-mobile"
        id={toggleSideBar ? "btn-mobile-ativo" : "btn-mobile-inativo"}
        onClick={toggleMenu}
      >
        <span id="hamburger"> </span>
      </button>
      <div className="img-avatar">
        <img
          src={user.avatarUrl == null ? avatar : user.avatarUrl}
          alt="Foto avatar"
        />
      </div>

      <div className="info">
        <p>
          Ranking:{" "}
          {user.cidade == null ? (
            <GoAlert
              style={{ color: "yellow", marginLeft: 10 }}
              size="16"
              aria-owns={open ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            />
          ) : (
            user.rank
          )}
        </p>
        <p>Pontos: {pontos}</p>

        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: "none",
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 0.5 }}>
            Para aparecer na lista de empresas e necessário <br />
            cadastrar sua cidade na sessão "Minha Empresa"
          </Typography>
        </Popover>
      </div>

      <div id="menu">
        <Link
          to="/dashboard"
          className={
            location.pathname === "/dashboard"
              ? "highlight-sidebar"
              : "lowlight"
          }
        >
          <TbListDetails
            size={24}
            className={
              location.pathname === "/dashboard"
                ? "highlight-svg"
                : "lowlight-svg"
            }
          />
          Lista das Empresa
        </Link>
        <Link
          to="/profile"
          className={
            location.pathname === "/profile" ? "highlight-sidebar" : "lowlight"
          }
        >
          <MdStoreMallDirectory
            size={24}
            className={
              location.pathname === "/profile"
                ? "highlight-svg"
                : "lowlight-svg"
            }
          />
          Minha Empresa{" "}
          {(user.cidade == null || user.cidade == "") && (
            <GoAlert style={{ color: "yellow", marginLeft: 10 }} />
          )}
        </Link>
        <Link
          to="/fale-conosco"
          className={
            location.pathname === "/fale-conosco"
              ? "highlight-sidebar"
              : "lowlight"
          }
        >
          <MdOutlineSpeakerNotes
            size={24}
            className={
              location.pathname === "/fale-conosco"
                ? "highlight-svg"
                : "lowlight-svg"
            }
          />
          Fale Conosco
        </Link>

        {user.isAdmin && (
          <Link
            to="/admin"
            className={
              location.pathname === "/admin" ? "highlight-sidebar" : "lowlight"
            }
          >
            <RiAdminLine
              size={24}
              className={
                location.pathname === "/admin"
                  ? "highlight-svg"
                  : "lowlight-svg"
              }
            />
            Administração
          </Link>
        )}
      </div>

      <footer>
        <button className="logout-btn" onClick={() => signOutSystem()}>
          <FiLogOut />
          Sair
        </button>
        <img className="logo" src={logo} alt="Foto logo" />
      </footer>
    </div>
  );
}
