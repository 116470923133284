import Header from  "../../components/HeaderSite"
import Contact from  "../../components/ContactForm"

export default function(){
  
  return(<>
      <Header theme="dark" />    
      <Contact/>    
    </>
    )
}