
import './signin.css';
import { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { AuthContext } from '../../contexts/auth';
import login_img from './img/login.png';
import register_img from './img/register.png';
import logo from '../../assets/logo-way.png';
import React from 'react';
import { MdOutlineMailOutline, MdBusiness } from "react-icons/md";
import { RiKeyFill } from "react-icons/ri";
import { toast } from 'react-toastify';


function SignIn() {

  const { login } = useParams();
   
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [esqueciPwd, setEsqueciPwd] = useState(false);

  const { signIn, signUp, recuperarSenha, loadingAuth } = useContext(AuthContext);
  const [iscontainer2Active, setIscontainer2Active] = React.useState(false);

  useEffect(()=>{
    if(login=="true")
      setIscontainer2Active(false);

    if(login=="false")
      setIscontainer2Active(true);

  },[])

  function handleSignup(e){
    e.preventDefault();
    if(empresa !== '' && email !== '' && password !== ''){
      signUp(email, password, empresa);
    }else{
      toast.error('Preencha todos os campos!')
    }
  }

  function handleSignIn(e){
    if(!esqueciPwd){
      e.preventDefault();    
      if(email !== '' && password !== ''){
        signIn(email, password);
      }else{
        toast.error('Preencha todos os campos!')
      }
    }else{
      //Firebase esqueci senha
      if(email !== '')
        recuperarSenha(email);
        setEsqueciPwd(false);
    }

  }  

  function esqueciSenha(){
    if(esqueciPwd)
      setEsqueciPwd(false);
    else
      setEsqueciPwd(true);
  }

     const signUpButton = () => {
        setIscontainer2Active(false);
        setEsqueciPwd(false);
     };  
     const signInButton = () => {
        setIscontainer2Active(true);
     };

    

  return (
    <div className="container2-center">

    <div className={`container2${iscontainer2Active ? " sign-up-mode" : ""}`} >
      <div className="forms-container2" >
        <div className="signin-signup">
          <form action="#" onSubmit={handleSignIn} className="sign-in-form">
            <img src={logo} className="logoImage" alt="login imagem" />
            <h2 className="title">{esqueciPwd? "Redefinir Senha" : "Entrar" }</h2>
            <div className="input-field">
              <MdOutlineMailOutline size={20}/>
              <input className='input-email' type="text" onChange={(e)=>setEmail(e.target.value)} value={email} placeholder="Email" />
            </div>
            { !esqueciPwd &&
              <div className="input-field">
                <RiKeyFill size={20}/>
                <input className='input-password' type="password" onChange={(e)=>setPassword(e.target.value)} value={password} placeholder="Senha" />
              </div>
            }
              <input type="submit" value={loadingAuth ? 'Carregando...' : esqueciPwd? 'Redefinir senha' :'ENTRAR'} className="btn solid" />
            <input type="button" className="link-login" onClick={esqueciSenha} value={esqueciPwd?"Fazer Login":"Esqueci a senha / Trocar minha senha"}/>
            {/*
            <p class="social-text">Or Sign in with social platforms</p>
            <div class="social-media">
              <a href="#" class="social-icon">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-twitter"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-google"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-linkedin-in"></i>
              </a>
            </div>
            */}
          </form>
          <form action="#" onSubmit={handleSignup} className="sign-up-form">
            <img src={logo} className="logoImage" alt="login imagem" />
            <h2 className="title">Cadastrar</h2>
            <div className="input-field">
              <MdBusiness size={20}/>
              <input className='input-empresa' type="text" value={empresa} onChange={(e)=>setEmpresa(e.target.value)} placeholder="Nome da empresa" />
            </div>
            <div className="input-field">
              <MdOutlineMailOutline size={20}/>
              <input className='input-email' type="email" value={email} onChange={(e)=>setEmail(e.target.value)} placeholder="Email" />
            </div>
            <div className="input-field">
              <RiKeyFill size={20}/>
              <input className='input-password' type="password" value={password} onChange={(e)=>setPassword(e.target.value)} placeholder="Senha" />
            </div>
            <input type="submit" className="btn" value={loadingAuth ? 'Carregando...' : 'Cadastrar'} />
            {/*
            <p class="social-text">Esqueceu a senha? clique aqui!</p>
            
            <div class="social-media">
              <a href="#" class="social-icon">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-twitter"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-google"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-linkedin-in"></i>
              </a>
            </div>
            */}
          </form>
        </div>
      </div>

      <div className="panels-container2" >
        <div className="panel left-panel" >
          <div className="content-log">
            <h2>Ainda não tem conta?</h2>
            <p>
              Cadastre-se e tenha acesso a todos os benefícios da comunidade !
            </p>
            <button className="btn transparent" id="sign-up-btn" onClick={signInButton}>
              Cadastre-se
            </button>
          </div>
          <img src={login_img} className="image" alt="login imagem" />
        </div>
        <div className="panel right-panel">
          <div className="content-log">
            <h1>Já tem conta ?</h1>
            <p>
              Entre, a casa é sua !
            </p>
            <button className="btn transparent" id="sign-in-btn" onClick={signUpButton}>
              Entrar
            </button>
          </div>
          <img src={register_img} className="image" alt="registro imagem" />
        </div>
      </div>
    </div>

    </div>
  );
}

export default SignIn;



