import {useState} from "react"
import logo from '../../assets/logo-way.png'
import '../HeaderSite/header-site.css'

export default function(params){

    const [ativo, setAtivo] = useState(false);

    return(

        <header className={params.theme=="dark"?"header-area header-sticky dark":"header-area header-sticky"}>
            <div className="container-site">
                <div className="row-site">
                    <div className="col-12">
                        <nav className="main-nav">
                            {/* *** Header start  *** */}
                            <a href="/" >
                                <img className="logo" src={logo} alt='logo' />
                            </a>
                            {/* *** Menu start  *** */}
                            <div className="nav" >
                                <a className="contato" href="/contact">Contato</a>
                                <a href="/login/true" className="main-button-login">Entre</a>
                                <a href="/login/false" className="main-button-slider">Cadastre sua empresa</a>
                            </div>
                            {/* *** Menu Mobile   ****/}
                            <div className="sidenav" style={{width:ativo?263:0}} >
                                <a className="contato" href="#contact-us">Contato</a>
                                <a href="/login/true" className="main-button-login">Entre</a>
                                <a href="/login/false" className="main-button-slider">Cadastre sua empresa</a>
                            </div>
                            <a className={ativo?'menu-trigger active':'menu-trigger'} onClick={()=> setAtivo(!ativo)}>
                                <span>Menu</span>
                            </a>
                        {/* *** Menu End  *** */}
                        </nav>
                    </div>
                </div>
            </div>
        </header>

    )
}