
import { useState, useContext, useEffect } from 'react';
import './profile.css';
import Header from '../../components/Header';
import avatar from '../../assets/avatar.png';
import camera from '../../assets/camera-white.png';
import { doc, setDoc, updateDoc, getDoc } from "firebase/firestore";
import { db, storage } from '../../services/firebaseConnection';
import { AuthContext } from '../../contexts/auth';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import Resizer from "react-image-file-resizer";

import { GoVerified, GoX, GoAlert } from 'react-icons/go';

import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { toast } from 'react-toastify';
import MaskedInput from 'react-text-mask';
import { 
  TextField,
  Checkbox,
  FormControlLabel,
  TextareaAutosize,
  Chip,
  Select,
  MenuItem,
  Box,
  OutlinedInput,
  InputLabel,
  FormControl,
  Autocomplete,
} from '@mui/material';

export default function Profile(){
  const { 
    user, 
    auth,
    setUser, 
    storageUser, 
    sendEmailV, 
    toggleSideBar,
    gravarLog,
    setToggleSideBar
  } = useContext(AuthContext);

  const userRef = doc(db, 'empresas',  user.uid);

  const [nome, setNome] = useState(user && user.nome);
  const [email] = useState(user && user.email);
  const [empresa, setEmpresa] = useState(user && user.empresa);
  const [desc, setDesc] = useState(user && user.desc);
  const [cidade, setCidade] = useState(user && user.cidade);
  const [endereco, setEndereco] = useState(user && user.endereco);
  const [whatsapp, setWhatsapp] = useState(user && user.whatsapp);
  const [telefone, setTelefone] = useState(user && user.telefone);
  const [site, setSite] = useState(user && user.site);
  const [instagram, setInstagram] = useState(user && user.instagram);
  const [ramoAtividade, setRamoAtividade] = useState(user.ramoAtividade == null ? [] : user.ramoAtividade);
  const [listaRamoAtividade, setListaRamoAtividade] = useState([]);
  const [listaCidades, setListaCidades] = useState([]);
  const [desconto, setDesconto] = useState(user && user.desconto);
  const [loadRamoAtividade, setLoadRamoAtividade] = useState(true);
  const [loadCidades, setLoadCidades] = useState(true);
  const [loadSave, setLoadSave] = useState(false);

  const [avatarUrl, setAvatarUrl] = useState(user && user.avatarUrl);
  const [imageAvatar, setImageAvatar] = useState(null);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  function getMask(value) {
    const telFixoMask = ['(', /[1-9]/, /\d/, ')', ' ', /\d/,/\d/,/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    const telCelMask = ['(', /[1-9]/, /\d/, ')', ' ', /\d/,' ',/\d/,/\d/,/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  
    if(value.length <= 14){
      return telFixoMask;
    }else{
      return telCelMask;
    }
  }

  function responsiveColumns(){
    if(document.body.clientWidth > 900){
      if(typeof toggleSideBar != "undefined") setToggleSideBar(false);
    }else if (document.body.clientWidth > 510){
      if(typeof toggleSideBar != "undefined") setToggleSideBar(true);
    }else if (document.body.clientWidth > 0){
      if(typeof toggleSideBar != "undefined") setToggleSideBar(true);
    }
  };  


  useEffect(()=> {

    async function loadRamoAtividade(){
      let lista = [];
      let ramoRef = doc(db, "ramoAtividade", "o9aTcnd6RDGzsbkYwDac");
      const querySnapshot = await getDoc(ramoRef);    
      lista = querySnapshot.data().ramo;      
      lista.sort()
      setListaRamoAtividade(lista);
      setLoadRamoAtividade(false);        
    }

    async function loadCidades(){
      let lista = [];
      let cidadesRef = doc(db, "cidades", "altoTiete");
      const querySnapshot = await getDoc(cidadesRef);
      lista = querySnapshot.data().cidades;
      lista.sort()
      setListaCidades(lista);
      setLoadCidades(false);
    }

    loadRamoAtividade();
    loadCidades();
    responsiveColumns();

  }, []);

  function handleFile_old(e){
    if(e.target.files[0]){
      const image = e.target.files[0];
      if(image.type === 'image/jpeg' || image.type === 'image/png'){

        setImageAvatar(image);
        setAvatarUrl(URL.createObjectURL(e.target.files[0]))

      }else{
        alert('Envie uma imagem do tipo PNG ou JPEG');
        setImageAvatar(null);
        return null;
      }
    }
  }

  const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
        //setImageAvatar(uri);
        //setAvatarUrl(URL.createObjectURL(uri));
      },
      "file"
    );
  });

  const handleFile = async (event) => {
    try {
      const file = event.target.files[0];
      const image = await resizeFile(file);
      setImageAvatar(image);
      setAvatarUrl(URL.createObjectURL(image));
      console.log(image);
    } catch (err) {
      console.log(err);
    }
  };


  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 5;  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function ajusteInstagram(e){
    let insta = e.target.value;
    insta = insta.replace('@', '').replace('https://instagram.com/', '');
    setInstagram(insta);
  }

  function ajusteSite(e){
    e.preventDefault();
    let urlSite = e.target.value;
    urlSite = urlSite.replace('http://', '').replace('https://', '');
    setSite(urlSite.toLowerCase());
  }

  async function handleUpload(){
    const currentUid = user.uid;

    const storageRef = ref(storage, `images/${currentUid}/${imageAvatar.name}`);

    uploadBytes(storageRef, imageAvatar)
    .then( async () => {
      console.log('Foto enviada com sucesso');
      await getDownloadURL(storageRef)
      .then( async (url)=>{
        let urlFoto = url;
        await updateDoc(userRef, {
          avatarUrl: urlFoto,
          empresa: empresa,
          nome: nome == null? null: nome,
          instagram: instagram == null? null: instagram,
          telefone: telefone == null? null: telefone,
          whatsapp: whatsapp == null? null: whatsapp,
          site: site == null? null: site,
          cidade: cidade == null? null: cidade,
          endereco: endereco == null? null: endereco,
          desc: desc == null? null: desc,
          ramoAtividade: ramoAtividade == null? null: ramoAtividade,
          desconto: desconto == null? null: desconto,
        }, {merge:true})
        .then(()=>{
          let data = {
            ...user, 
            avatarUrl: urlFoto,
            empresa: empresa,
            nome: nome == null? null: nome,
            instagram: instagram == null? null: instagram,
            telefone: telefone == null? null: telefone,
            whatsapp: whatsapp == null? null: whatsapp,
            site: site == null? null: site,
            cidade: cidade == null? null: cidade,
            endereco: endereco == null? null: endereco,
            desc: desc == null? null: desc,
            ramoAtividade: ramoAtividade == null? null: ramoAtividade,
            desconto: desconto == null? null: desconto,
              };
          setUser(data);
          storageUser(data);
          setLoadSave(false);
          gravarLog(user.uid, "Perfil Atualizado");
          toast.success("Perfil atualizado com sucesso!" );
        })
        .catch((error)=>{
          setLoadSave(false);
          toast.error("Falha ao Salvar");
          console.log(error);
        })
      })
    })
  }

  async function handleSave(e){
    e.preventDefault();
    setLoadSave(true);
    
    if(imageAvatar == null){  
      await setDoc(userRef, {
        empresa: empresa,
        nome: nome == null? null: nome,
        instagram: instagram == null? null: instagram,
        telefone: telefone == null? null: telefone,
        whatsapp: whatsapp == null? null: whatsapp,
        site: site == null? null: site,
        cidade: cidade == null? null: cidade,
        endereco: endereco == null? null: endereco,
        desc: desc == null? null: desc,
        ramoAtividade: ramoAtividade == null? null: ramoAtividade,
        desconto: desconto == null? null: desconto,
      }, {merge:true})
      .then(()=>{
        let data = {
          ...user,
          empresa: empresa,
          nome: nome == null? null: nome,
          instagram: instagram == null? null: instagram,
          telefone: telefone == null? null: telefone,
          whatsapp: whatsapp == null? null: whatsapp,
          site: site == null? null: site,
          cidade: cidade == null? null: cidade,
          endereco: endereco == null? null: endereco,
          desc: desc == null? null: desc,
          ramoAtividade: ramoAtividade == null? null: ramoAtividade,
          desconto: desconto == null? null: desconto,
        };
        setUser(data);
        storageUser(data);
        setLoadSave(false);
        gravarLog(user.uid, "Perfil Atualizado");
        toast.success("Cadastro atualizado com sucesso!", {theme: "colored"})
      })
    }
    else if(imageAvatar !== null){
      handleUpload();
    }
  }

  const handleChange = (event, newValue) => {
    setRamoAtividade(newValue);
  };

  return(
    <div className='div-geral'>
      <Header/>

      <div className="content" id={toggleSideBar?"content-ativo": "content-inativo"}>
        {/*
          <Title name="Minha Empresa">
            <FiSettings size={25} />
          </Title>
        */}

        <div className="container">
          <form className="form-profile" autoComplete='off' onSubmit={handleSave}>

            <div className="personal-image">
              <label className="label">
                <input type="file" accept="image/*" onChange={handleFile}/>
                <figure className="personal-figure">
                  { avatarUrl == null ? 
                    <img className='personal-avatar' src={avatar} alt="Foto de perfil do usuario" />
                    :
                    <img className='personal-avatar' src={avatarUrl} alt="Foto de perfil do usuario" />
                  }
                  <figcaption className="personal-figcaption">
                    <img className='personal-avatar' alt="camera" src={camera}/>
                  </figcaption>
                </figure>
              </label>

              <div className='status'>  
                <div className='email-verificado'>
                  {user.emailVerificado?
                    <GoVerified className='verificado-icon' size={20} />
                    :
                    <GoX className='non-verificado-icon' size={20} />
                  }
                  <label>Email Verificado</label>
                </div>
                  <div>
                { !user.emailVerificado &&
                      <button 
                        type='button' 
                        onClick={()=>sendEmailV(auth.currentUser)}
                        > 
                        reenviar email de verificação
                      </button>                                          
                }
                  </div>
              </div>
            </div>
            
            <div className='div-campos'>
              <div className='c1'>
                <span>
                  <TextField
                    label="Nome da Empresa"
                    id="outlined-size-small"
                    type="text"
                    defaultValue={empresa}
                    size="small"
                    color='warning'
                    onChange={ (e) => setEmpresa(e.target.value) }
                  />                  
                </span>
                <span>
                  <TextField
                    label="Nome do Responsável"
                    id="outlined-size-small"
                    type="text"
                    defaultValue={nome}
                    size="small"
                    color='warning'
                    onChange={ (e) => setNome(e.target.value) }
                  />
                </span>             
              </div>

              <div className='c1'>
                
                  {loadRamoAtividade?(
                    <span>
                      <TextField
                        sx={{ width: 350 }}
                        type="text"
                        defaultValue="carregando..."
                        size="small"
                      />
                    </span>
                  ) :
                  (
                    <span>    
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={listaRamoAtividade}
                        value={ramoAtividade}
                        disableCloseOnSelect
                        color='warning'
                        onChange={handleChange}
                        limitTags={2}
                        getOptionLabel={(option) => option}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                              color='warning'
                            />
                            {option}
                          </li>
                        )}
                        style={{ width: 300}}
                        renderInput={(params) => (
                          <TextField {...params} label="Ramo de Atividade" size='small' color='warning' placeholder="Digite..." />
                        )}
                      />
                    </span>
                  )}  

                  
                <span>
                <TextField
                  label="Desconto (%)"
                  id="outlined-size-small"
                  type="number"
                  sx={{ width: 135 }}
                  defaultValue={desconto}
                  size="small"
                  color='warning'
                  helperText='Opcional'
                  onChange={ (e) => setDesconto(e.target.value) }
                />
                </span>
                <span>
                  <TextField
                    label="Instagram"
                    id="outlined-size-small"
                    type="text"
                    defaultValue={instagram}
                    size="small"
                    placeholder="meu_instagram"
                    color='warning'
                    onChange={ (e) => ajusteInstagram(e) }
                  />
                </span>                
              </div>

              <div className='c2'>
                <span className='telefone'>  
                  <MaskedInput
                    className='masked'
                    value={telefone}
                    mask={getMask}
                    guide={false}
                    placeholder="Telefone"
                    onChange={(e) => setTelefone(e.target.value)}
                  />
                </span>

                <span className='whatsapp'>
                  <FormControlLabel 
                    label="É WhatsApp" 
                    control={
                      <Checkbox 
                        color='success' 
                        checked={whatsapp}
                        onChange={(e) => setWhatsapp(e.target.checked)}
                      />} 
                    
                  />
                                  
                </span>   
                <span>
                  <TextField
                    label="Site"
                    id="outlined-size-small"
                    type="text"
                    defaultValue={site}
                    size="small"
                    placeholder="www.empresa-site.com.br"
                    color='warning'
                    onChange={ (e) => ajusteSite(e) }
                  />                
                </span>             
              </div>
              <div className='c3'>
                <div className='alert'>
                  {(cidade == null|| cidade =="")&&<GoAlert style={{color:"orange", marginTop: 25}}/>}
                  <span>
                      <Autocomplete  
                        id="combo-box"
                        sx={{ width: 250 }}
                        options={listaCidades}
                        value={cidade}  
                        onChange={ (event, newValue) => setCidade(newValue) }
                        renderInput={(params) => 
                          <TextField {...params} 
                            color='warning'
                            helperText={(cidade == null|| cidade =="")&&"Para aparecer na lista das empresas, cadastre uma cidade!"}
                            label="Cidade" 
                            size='small'                         
                          />
                        }
                      />                  
                  </span>
                </div>

                <span>
                <TextField
                    label="Endereço"
                    type="text"
                    defaultValue={endereco}
                    size="small"
                    placeholder="Rua Noventa, 714"
                    color='warning'
                    onChange={ (e) => setEndereco(e.target.value) }
                  />                      
                </span>
              </div>
              <div className='c4'>
                <span>
                  <TextField
                        label="email"
                        type="text"
                        defaultValue={email}
                        size="small"
                        width='300px'
                        color='warning'
                        disabled
                  />                    
                </span>
              </div>

            </div>
            
            <div className='div-textarea'>
              <label>Descrição </label>
              <TextareaAutosize  
                label="Descrição"
                className='textarea-desc'
                value={desc===null?"":desc}        
                minRows='10'  
                color='warning'
                placeholder='Fale sobre a empresa e quais produtos ou serviços são oferecidos.'
                onChange={(e)=> setDesc(e.target.value)}
              />  

            <button className='btn' type="submit">{loadSave? "Salvando..." : "Salvar" }</button>

            </div>

          </form>
        </div>
      </div>
    </div>
  )
}