import { useState, Fragment } from "react"
import './style/site.css'
import './style/bootstrap.min.css'

import imageHeader from './images/image-site_2.png'
import logo from '../../assets/logo-way.png'
import DataTable from '../../components/DataTable'
import { Alert } from 'react-st-modal';
import { version } from "../../defaults/version"

export default function Site(){

    const [ativo, setAtivo] = useState(false);

    const modal = () => {
        const result = Alert(
          "A fim de fomentar mais negócios entre os membros da WayHouseChurch, "+ 
          "desenvolvemos esta ferramenta para você que é empreendedor e tem a sua empresa/marca, quer divulgar seu trabalho e serviços entre os irmãos, cadastre-se e faça sua divulgação. " +
          "Podem divulgar seus serviços prestados, promoções e descontos.",
          "WayNetwork"
          
          );
      }

    const handleClickScroll = () => {
        const element = document.getElementById('datatable');
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };

return(
    <>

    <header className="header-area header-sticky">
        <div className="container-site">
            <div className="row-site">
                <div className="col-12">
                    <nav className="main-nav">
                        {/* *** Header start  *** */}
                        <a href="#" >
                            <img className="logo" src={logo} alt='logo' />
                        </a>
                        {/* *** Menu start  *** */}
                        <div className="nav" >
                            <a className="contato" href="/contact">Contato</a>
                            <a href="/login/true" className="main-button-login">Entre</a>
                            <a href="/login/false" className="main-button-slider">Cadastre sua empresa</a>
                        </div>
                        {/* *** Menu Mobile   ****/}
                        <div className="sidenav" style={{width:ativo?263:0}} >
                            <a className="contato" href="/contact">Contato</a>
                            <a href="/login/true" className="main-button-login">Entre</a>
                            <a href="/login/false" className="main-button-slider">Cadastre sua empresa</a>
                        </div>
                        <a className={ativo?'menu-trigger active':'menu-trigger'} onClick={()=> setAtivo(!ativo)}>
                            <span>Menu</span>
                        </a>
                    {/* *** Menu End  *** */}
                    </nav>
                </div>
            </div>
        </div>
    </header>

        {/* *** Welcome area  *** */}
        <div className="welcome-area" id="welcome">

        {/* *** Header  text start  *** */}
        <div className="header-text">
            <div className="container-site">
                <div className="row-site">
                    <div className="left-text col-lg-6 col-md-6 col-sm-12 col-xs-12" data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">
                        <h1>Encontre <strong>aqui</strong> as melhores empresas, produtos e serviços 
                            do <strong> Alto-Tietê</strong>
                        </h1>
                        <p>A WayNetwork é uma iniciativa da igreja <a target="_blank" rel='noreferrer' href="https://wayhousechurch.com.br">WayHouseChurch</a> para conectar empresas e pessoas. <a href="#" onClick={modal}>Saiba Mais...</a></p>
                        <button
                            className="main-button-header"
                            onClick={handleClickScroll}
                        >
                        Pesquisar empresa, produto ou serviço
                        </button>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12" data-scroll-reveal="enter right move 30px over 0.6s after 0.4s">
                        <img src={imageHeader} className="rounded img-fluid d-block mx-auto" alt="First Vector Graphic"/>
                    </div>
                </div>
            </div>
        </div>
        {/* *** Header text end  *** */}
    </div>
        {/* *** Welcome area End  *** */}







    <section className='section' id='datatable'>
        <div className='dt'>
            <DataTable pageSize={15} admin={false} page="site"/>
        <p className="version">v{version}</p>
        </div>
    </section>

    </>
)
}