import { useContext, useEffect } from "react";
import SideBar from "../../components/Header"
import ContactForm from  "../../components/ContactForm"
import { AuthContext } from '../../contexts/auth';

export default function FaleConosco(){
    const { toggleSideBar, setToggleSideBar } = useContext(AuthContext);

    useEffect(()=>{
        responsiveColumns();

    },[])

    function responsiveColumns(){
        if(document.body.clientWidth > 900){
          if(typeof toggleSideBar != "undefined") setToggleSideBar(false);
        }else if (document.body.clientWidth > 510){
          if(typeof toggleSideBar != "undefined") setToggleSideBar(true);
        }else if (document.body.clientWidth > 0){
          if(typeof toggleSideBar != "undefined") setToggleSideBar(true);
        }
      };  
    

    return(
        <div className='content' id={toggleSideBar?"content-ativo": "content-inativo"}>
            <SideBar/>
            <ContactForm/>
        </div>
    )
}