import { useState, createContext, useEffect } from "react";
import { db } from "../services/firebaseConnection";
import { getAnalytics, logEvent } from "firebase/analytics";
import axios from "axios";
import {
  setDoc,
  getDoc,
  doc,
  updateDoc,
  Timestamp,
  increment,
  collection,
} from "firebase/firestore";
import { toast } from "react-toastify";
import {
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  getAuth,
  signOut,
  signInWithEmailAndPassword,
} from "firebase/auth";

export const AuthContext = createContext({});

function AuthProvider({ children }) {
  const auth = getAuth();
  const [user, setUser] = useState(null);
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const [toggleSideBar, setToggleSideBar] = useState(false);

  const pontosRef = doc(db, "pontos", "altoTiete");
  const analytics = getAnalytics();

  useEffect(() => {
    function loadStorage() {
      const storageUser = localStorage.getItem("SistemaUser");

      if (storageUser) {
        setUser(JSON.parse(storageUser));
        setLoading(false);
      }
      setLoading(false);
    }

    loadStorage();
    getIP();
  }, []);

  //Pega IP
  const getIP = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    let ipv4 = res.data.IPv4;
    const meses = [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ];
    let data = new Date();
    let dataFormatada =
      data.getDate() + " " + meses[data.getMonth()] + " " + data.getFullYear();
    let ipRef = doc(
      db,
      "relatorios",
      "altoTiete",
      "acessos",
      dataFormatada,
      "ips",
      ipv4
    );

    let retorno = await getDoc(ipRef);
    if (retorno.exists()) {
      console.log("já acessou hoje");
    } else {
      console.log("criando doc");
      await setDoc(ipRef, res.data, { merge: true }).then(() => {
        console.log("passou por aqui");
        let contagemAcessosRef = doc(
          db,
          "relatorios",
          "altoTiete",
          "acessos",
          dataFormatada
        );
        setDoc(
          contagemAcessosRef,
          { contagem: increment(1) },
          { merge: true }
        ).then(() => console.log("deu certo"));
      });
    }
  };

  function reloadUser() {
    if (auth.currentUser == null) return;
    auth.currentUser.reload();
  }

  function somaPontosEmailVerificado(email) {
    if (auth.currentUser == null || user == null) return;
    let emailVerificadoAuth = auth.currentUser.emailVerified;
    let docRef = doc(db, "empresas", email);
    getDoc(docRef).then((docUser) => {
      if (emailVerificadoAuth && !user.emailVerificado) {
        // Acrescentando pontos
        getDoc(pontosRef).then((pontos) => {
          gravarLog(user.uid, "Email Autenticado");
          updateDoc(docRef, {
            //pontos: increment(pontos.data().emailVerificado),
            pontos: docUser.data().pontos + pontos.data().emailVerificado,
          })
            .then(() => {
              // atualiza o campo emailVerificado
              updateDoc(docRef, { emailVerificado: true })
                .then(() => {
                  let data = {
                    ...user,
                    emailVerificado: true,
                  };
                  setUser(data);
                  storageUser(data);
                  setLoadingAuth(false);
                })
                .catch((error) => {
                  console.log("error ao salvar email verificado: " + error);
                });
            })
            .catch((error) => {
              console.log(error);
            });
        });

        //colocar 50 pontos
      }
    });
  }

  //Recuperando a senha
  async function recuperarSenha(email) {
    await sendPasswordResetEmail(auth, email)
      .then(() => {
        toast.success("email enviado com sucesso", { theme: "colored" });
      })
      .catch((error) => {
        toast.error("Erro: " + error, { theme: "colored" });
      });
  }

  async function gravarUltimoAcesso(usuario) {
    let docRef = doc(db, "empresas", usuario.uid);
    let agora = Timestamp.fromDate(new Date());
    updateDoc(docRef, { ultimoAcesso: agora }).then(() => {
      //setUser({...usuario, ultimoAcesso: agora});
    });
  }
  async function gravarLog(uid, action) {
    let logRef = doc(collection(db, "empresas", uid, "log"));
    const agora = new Date();
    await setDoc(logRef, { date: agora, log: action }).catch((error) => {
      console.log("erro ao gravar log: " + error);
    });
  }

  //Fazendo login do usuário
  async function signIn(email, password) {
    setLoadingAuth(true);

    signInWithEmailAndPassword(auth, email, password)
      .then(async (value) => {
        let uid = value.user.email;
        let docRef = doc(db, "empresas", uid);

        let userProfile = await getDoc(docRef);
        let data = {
          uid: uid,
          email: value.user.email,
          avatarUrl: userProfile.data().avatarUrl,
          cidade: userProfile.data().cidade,
          desc: userProfile.data().desc,
          empresa: userProfile.data().empresa,
          endereco: userProfile.data().endereco,
          instagram: userProfile.data().instagram,
          nome: userProfile.data().nome,
          site: userProfile.data().site,
          telefone: userProfile.data().telefone,
          whatsapp: userProfile.data().whatsapp,
          ramoAtividade: userProfile.data().ramoAtividade,
          membroWay: userProfile.data().membroWay,
          dataCadastro: userProfile.data().dataCadastro,
          desconto: userProfile.data().desconto,
          isAdmin: userProfile.data().isAdmin,
          emailVerificado: userProfile.data().emailVerificado,
          pontos: userProfile.data().pontos,
          ultimoAcesso: userProfile.data().ultimoAcesso,
        };
        setUser(data);
        storageUser(data);
        setLoadingAuth(false);

        gravarUltimoAcesso(data);
        gravarLog(uid, "Login");
        logEvent(analytics, "login");

        toast.success("Bem vindo de volta!");
      })
      .catch((error) => {
        console.log(error);
        setLoadingAuth(false);
        toast.error("Falha no Login! Verifique o email e senha.", {
          theme: "colored",
        });
      });
  }

  function sendEmailV(authUser) {
    sendEmailVerification(authUser)
      .then(() => {
        toast.success(
          "Verifique seu email (inclusive no spam) para confirmar sua entrada na comunidade!",
          { autoClose: 10000 }
        );
      })
      .catch((error) => {
        console.log("erro ao enviar email de confirmação: " + error);
      });
  }

  // Cadastrando um novo usuário
  async function signUp(email, password, empresa) {
    setLoadingAuth(true);

    await createUserWithEmailAndPassword(auth, email, password)
      .then(async (value) => {
        // pega pontos de cadastro
        const pontos = await getDoc(pontosRef);

        let uid = value.user.email;
        let docRef = doc(db, "empresas", uid);
        await setDoc(docRef, {
          avatarUrl: null,
          empresa: empresa,
          dataCadastro: new Date(),
          ultimoAcesso: new Date(),

          email: value.user.email,
          emailVerificado: false,
          pontos: pontos.data().cadastro,
        }).then(() => {
          let data = {
            uid: uid,
            email: value.user.email,
            empresa: empresa,
            dataCadastro: new Date(),
            ultimoAcesso: new Date(),
            emailVerificado: false,
            pontos: pontos.data().cadastro,
          };

          setUser(data);
          storageUser(data);
          setLoadingAuth(false);
          gravarLog(uid, "cadastro");
          logEvent(analytics, "sign_up");

          toast.success("Bem vindo(a) comunidade!", { theme: "colored" });
        });
        sendEmailV(value.user);
      })
      .catch((error) => {
        console.log(error);
        setLoadingAuth(false);
        toast.error("Falha no cadastro!   " + error);
      });
  }

  function storageUser(data) {
    localStorage.setItem("SistemaUser", JSON.stringify(data));
  }

  // Logout do usuário
  async function signOutSystem() {
    await signOut(auth);
    localStorage.removeItem("SistemaUser");
    setUser(null);
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        loading,
        signUp,
        recuperarSenha,
        signOutSystem,
        signIn,
        loadingAuth,
        setUser,
        storageUser,
        sendEmailV,
        reloadUser,
        somaPontosEmailVerificado,
        toggleSideBar,
        setToggleSideBar,
        gravarUltimoAcesso,
        gravarLog,
        auth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
