import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "firebase/storage";

let firebaseConfig =
  typeof process.env.REACT_APP_FIREBASE_PROD == "undefined"
    ? {
        apiKey: "AIzaSyB4r1hvkAohvQfTb2Ni3PxaeBXyiB4uGcY",
        authDomain: "waynetwork-test.firebaseapp.com",
        projectId: "waynetwork-test",
        storageBucket: "waynetwork-test.appspot.com",
        messagingSenderId: "611138599309",
        appId: "1:611138599309:web:2959f0ffc5509c9f7aa5c4",
        measurementId: "G-373099HLLZ",
      }
    : {
        apiKey: "AIzaSyAEm2xIsT6Uv_lSPJ2Uzplm95CIJlhJxe0",
        authDomain: "waynetwork-mqbot.firebaseapp.com",
        projectId: "waynetwork-mqbot",
        storageBucket: "waynetwork-mqbot.appspot.com",
        messagingSenderId: "760739350214",
        appId: "1:760739350214:web:34f15210a9e1963aa0f9c7",
        measurementId: "G-Q2EXELXS1V",
      };

export const firebase = initializeApp(firebaseConfig);
export const db = getFirestore(firebase);
export const storage = getStorage(firebase);
