import Header from "../../components/Header";
import DataTable from "../../components/DataTable";
import { AuthContext } from "../../contexts/auth";
import { useContext, useEffect } from "react";
import { getAnalytics, logEvent } from "firebase/analytics";
import SearchAppBar from "../../components/SearchAppBar";

export default function Dashboard() {
  const analytics = getAnalytics();

  const { user, toggleSideBar, gravarUltimoAcesso } = useContext(AuthContext);

  useEffect(() => {
    //Atualiza a data do último acesso caso necessário
    if (user) {
      let timeStampFB = user.ultimoAcesso;
      let timeStampToString = new Date(timeStampFB);

      let diaHoje = new Date().toDateString();
      let dataFBformatada = timeStampToString.toDateString();

      if (dataFBformatada !== diaHoje) {
        gravarUltimoAcesso(user);
        logEvent(analytics, "login");
      }
    }
  }, []);

  return (
    <div>
      <Header setLabelFila={user.rank} />
      <div
        className="content"
        id={toggleSideBar ? "content-ativo" : "content-inativo"}
      >
        <DataTable pageSize={15} admin={false} />
      </div>
    </div>
  );
}
