import { useState } from 'react'
import TextField from '@mui/material/TextField';
import emailjs from '@emailjs/browser'; 
import { toast } from 'react-toastify';
import "./contact.css"

export default function(){

    const [loadEnviar, setLoadEnviar] = useState(false);
    const [errorNome, setErrorNome] = useState(false);
    const [errorMensagem, setErrorMensagem] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [nome, setNome] = useState("");
    const [email, setEmail] = useState("");
    const [mensagem, setMensagem] = useState("");

    function handleNome(e){
        setNome(e.target.value);
        setErrorNome(false);
      }
      function handleEmail(e){
        setEmail(e.target.value);
        setErrorEmail(false);
      }
      function handleMensagem(e){
        setMensagem(e.target.value);
        setErrorMensagem(false);
      }
    
  
    function submit(e){
      e.preventDefault();
      setLoadEnviar(true);
      if(nome == ""){
        setErrorNome(true);
        setLoadEnviar(false);
        return;
      }
      if(email == ""){
        setErrorEmail(true);
        setLoadEnviar(false);
        return;
      }
      if(mensagem == ""){
        setErrorMensagem(true);
        setLoadEnviar(false);
        return;
      }
  
      const templateParams = {
        from_name:nome, 
        message: mensagem,
        email: email,
        reply_to:email
      }
  
      emailjs.send("service_fjcciyt", "template_bxjs77j", templateParams, "iZ8_1TiwGOGkluico")
        .then((response)=>{
          toast.success("Mensagem enviado com sucesso!", {theme:"colored"});
          setNome("");
          setEmail("");
          setMensagem("");
          setLoadEnviar(false);
        }, (err)=>{
          toast.error("Erro ai enviar a mensagem", {theme:"colored"});
          setLoadEnviar(false);
        })
    }
  


    return(

    <div className="container-contact">

    <div className="wrapper">

        <div className="company__info">

            <h3 className="company__info--title">Nos mande uma mensagem</h3>

            <h6>Se você tem alguma dúvida, sugestão ou testemunho, nos envie uma mensagem que responderemos o mais breve possível.</h6>

        </div>

        <div className="contact">

            <h3>Entre em Contato</h3>

            <form onSubmit={submit}>            
                <div className="input">
                <TextField
                    className="campos pri"
                    onChange={handleNome}
                    value={nome}
                    error={errorNome? true: false}
                    id="filled-error"
                    label="Seu nome"
                    type="name"
                    color="warning"
                    variant="filled"
                    helperText={errorNome?"Este campo não pode ficar vazio": ""}
                />
                <TextField
                    className="campos pri"
                    onChange={handleEmail}
                    value={email}
                    type="email"
                    error={errorEmail? true: false}
                    id="filled-error"
                    label="Seu e-mail"
                    variant="filled"
                    color="warning"
                    helperText={errorEmail?"Este campo não pode ficar vazio": ""}
                />  
                </div>

                <div className="full">
                <TextField
                    className="campos"
                    onChange={handleMensagem}
                    value={mensagem}
                    error={errorMensagem? true: false}
                    id="outlined-multiline-static"
                    type="text"
                    label="sua mensagem"
                    multiline
                    rows={8}
                    color='warning'
                    variant="filled"
                    helperText={errorMensagem?"Este campo não pode ficar vazio": ""}
                />
                <button className='btn' type="submit">{loadEnviar? "Enviando..." : "Enviar" }</button>

                </div>

            </form>

        </div>

    </div>

    </div>


    )
}

