import Header from '../../components/Header';
import CadastroRamo from '../../components/CadastroRamo';
import CadastroCidade from '../../components/CadastroCidade';
import AdminEmpresas from '../../components/AdminEmpresas';
import { AuthContext } from '../../contexts/auth';
import { useContext, useState, useEffect } from "react";
import { Redirect } from 'react-router-dom';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import "./admin.css";

export default function Admin(){

  const { user, toggleSideBar, setToggleSideBar } = useContext(AuthContext);
  const [value, setValue] = useState('1');

  useEffect(()=>{
    responsiveColumns();
  }, [])

  if(!user.isAdmin || user.isAdmin == null){
    console.log("passou aqui")
    return <Redirect to="/dashboard" />
  }

  function responsiveColumns(){
    if(document.body.clientWidth > 900){
      if(typeof toggleSideBar != "undefined") setToggleSideBar(false);
    }else if (document.body.clientWidth > 510){
      if(typeof toggleSideBar != "undefined") setToggleSideBar(true);
    }else if (document.body.clientWidth > 0){
      if(typeof toggleSideBar != "undefined") setToggleSideBar(true);
    }
  };  


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return(
    <div>
      <Header setLabelFila={user.rank}/>
      <div className='content' id={toggleSideBar?"content-ativo": "content-inativo"}>

          <TabContext value={value}>          
            <TabList onChange={handleChange} variant='scrollable' allowScrollButtonsMobile >
              <Tab label="Cadastro de Cidades" value="1" />
              <Tab label="Cadastro de Ramo" value="2" />
              <Tab label="Admin Empresas" value="3" />            
            </TabList>
            <TabPanel value="1">
              <CadastroCidade/>
            </TabPanel>
            <TabPanel value="2">
              <CadastroRamo/>
            </TabPanel>
            <TabPanel value="3">
              <AdminEmpresas/>
            </TabPanel>
          </TabContext>

      </div>
    </div>
  )
}