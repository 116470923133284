
import { Switch } from 'react-router-dom';
import Route from './Route';

import Site from '../pages/Site'
import SignIn from '../pages/Signin';

import Dashboard from '../pages/Dashboard';
import Profile from '../pages/Profile';
import Admin from '../pages/Admin';
import Contact from '../pages/Contact';
import FaleConosco from '../pages/FaleConosco';

export default function Routes(){
  return(
    <Switch>
      <Route exact path="/" component={Site} />
      <Route exact path="/login/:login?" component={SignIn} />

      <Route exact path="/dashboard" component={Dashboard} isPrivate />
      <Route exact path="/profile" component={Profile} isPrivate />
      <Route exact path="/admin" component={Admin} isPrivate />
      <Route exact path="/contact" component={Contact} />
      <Route exact path="/fale-conosco" component={FaleConosco} isPrivate />
    </Switch>
  )
}